@tailwind base;
@tailwind components;
@tailwind utilities;

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Inter", -apple-system, Helvetica Neue, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.link {
    @apply text-blue-600 cursor-pointer;
}

.link:hover {
    @apply text-blue-700;
}

.link-inverted {
    @apply underline;
}

.link-inverted:hover {
    @apply text-gray-700;
}

.htmlselect {
    @apply border rounded-md leading-10 text-base px-3 w-full;
    min-height: 44px;
}

.hide-scrollbar::-webkit-scrollbar, .hello-there > div::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar, .hello-there > div {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.no-scrollbars, .scrollable-content {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

.no-scrollbars::-webkit-scrollbar, .scrollable-content::-webkit-scrollbar {
    width: 1px;
}

.no-scrollbars::-webkit-scrollbar-track, .scrollable-content::-webkit-scrollbar-track {
    background: transparent;
}

.no-scrollbars::-webkit-scrollbar-thumb, .scrollable-content::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.no-scrollbars::-webkit-scrollbar, .scrollable-content::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.new-focus-standard {
    box-shadow: 0 1px 2px 0 #0000000d;
    border-color: #d4d4d8;
    border-width: 1px;
    border-style: solid;
}

.new-focus-standard:hover {
    border-color: #b3b3b3;
}

.new-focus-standard:focus-within {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(58, 151, 212, 0.36) 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
    border-color: #659adf;
}

/* SAFARI ONLY STYLES */

@media not all and (min-resolution: .001dpcm) {
    @media {
        input[type=radio] {
            transform: scale(2, 2);
            -moz-transform: scale(2, 2);
            -ms-transform: scale(2, 2);
            -webkit-transform: scale(2, 2);
            -o-transform: scale(2, 2);
        }
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.toasts {
    z-index: 9999;
}

.toasts.fixed-important {
    bottom: 0;
    right: 0;
}

.toasts.fixed-important > div {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
}

.link-entity {
    padding: 0 4px;
    border-radius: 6px;
}

.member.link-entity {
    cursor: pointer;
    white-space: nowrap;
    background-color: #E9F5FA;
    color: #003F96;
}

.member.link-entity:hover {
    background-color: #d7f0fc;
    color: #012d68;
}

.role.link-entity {
    cursor: pointer;
    white-space: nowrap;
    background-color: #fff6e1;
    color: #d77606;
}

.role.link-entity:hover {
    background-color: #f5dfc3;
    color: #d77606;
}

.group.link-entity {
    cursor: pointer;
    white-space: nowrap;
    background-color: #E9F5FA;
    color: #003F96;
}

.group.link-entity:hover {
    background-color: #d7f0fc;
    color: #012d68;
}

.simple-progress-bar-animate > span:after, .simple-progress-bar-animate > span > span {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
            -45deg,
            rgba(255, 255, 255, .2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, .2) 50%,
            rgba(255, 255, 255, .2) 75%,
            transparent 75%,
            transparent
    );
    z-index: 1;
    background-size: 30px 30px;
    animation: move 1.2s linear infinite;
    border-radius: 32px;
    overflow: hidden;
}

@keyframes moveProgress {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 30px 30px;
    }
}

.simple-progress-bar > span {
    display: block;
    transition: 0.1s width linear;
    height: 100%;
    border-radius: 32px;
    position: relative;
    overflow: hidden;
}

.simple-progress-bar-animate > span::after, .simple-progress-bar-animate > span > span {
    animation: moveProgress 1.2s linear infinite;
}

.simple-progress-bar-animate > span::after {
    display: none;
}

.inlineloader {
    height: 32px;
    width: 32px;
    color: rgba(90, 90, 90, 0.2);
    position: relative;
    display: inline-block;
    border: 3px solid;
    border-radius: 50%;
    border-right-color: #5a5a5a;
    animation: rotate 1s linear infinite;
}

.inlineloader.mini {
    height: 20px;
    width: 20px;
}

.inlineloader.inverted {
    color: rgba(255, 255, 255, 0.2);
    border-right-color: #fff;
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

.catch-up-table-view {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    column-gap: 2rem;
}

.catch-up-table-view-old {
    min-height: 70vh;
    height: 70vh;
    columns: 20rem;
    overflow: auto;
    gap: 1rem;
}

@media screen and (max-width: 768px) {
    .catch-up-table-view {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
    }
}

.h-svg-15 > svg {
    height: 1.5rem;
}

.z-9999 {
    z-index: 9999;
}

.em-emoji-picker {
    box-shadow: none;
    width: auto;
}

.compact-wrapper-text div.DraftEditor-editorContainer div[data-block=true] {
    margin-bottom: 0 !important;
}

.compact-wrapper-text div[data-block=true] span {
    white-space: normal;
}

.interest-tag-spacing > * {
    margin-right: 0.6rem !important;
    margin-bottom: 0.6rem !important;
}

.link-subtle {
    text-decoration-line: underline;
    text-decoration-color: #e0e0e0;
    text-underline-offset: 3px;
}

.link-subtle:hover {
    text-decoration-color: #afafaf;
}

@media screen and (max-width: 768px) {
    .mobile-drawer-fn {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        z-index: 49;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        border-top: 1px solid #cccccc;
    }
}

.h-4\.5 {
    height: 1.125rem;
}

.w-4\.5 {
    width: 1.125rem;
}

.svg-child-inherit > svg {
    width: inherit;
    height: inherit;
}

.svg-child-inherit-height > svg {
    width: inherit;
    height: inherit;
}

.svg-child-inherit > svg path:not([fill=none]), .svg-child-inherit > svg rect {
    fill: currentColor;
}

.content-inner-min-height {
    min-height: calc(100vh - 3.25rem);
}

.blur-white-bg {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.8);
}

.top-dynamic-header {
    top: 5.375rem;
}

.header_is-searching .top-dynamic-header {
    top: 3.125rem;
}

.bold-offset-label::before {
    content: attr(data-text);
    display: block;
    font-weight: bold;
    height: 0;
    visibility: hidden;
}

.standard-grid {
    display: grid;
    grid-gap: 1rem;
}

.udk-mm-menugrid {
    grid-template-columns: repeat(auto-fill, 100px);
}

.udk-mainmenu {
    height: 60px;
    display: grid;
    grid-template-columns: 1fr 1000px 1fr;
}

.udk-mainmenu-nav {
    display: grid;
    grid-gap: 4rem;
    grid-template-columns: 600px minmax(220px, 1fr);
}

.udk-menuicon > svg {
    height: 26px;
    width: 26px;
}

.udk-menuicon.is_active .icb2 {
    color: currentColor;
    opacity: 0.2;
}

.udk-menuicon:not(.is_active) .icb2 {
    color: #71717a;
    opacity: 0.075;
}

.udk-menuicon.is_active .icb1 {
    fill: currentColor;
}

.udk-menuicon:not(.is_active) .icb1 {
    fill: #71717a;
}

.udk-entitypage-rightsidebar {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.udk-mm-more {
    display: none;
}

.show-under-1023 {
    display: none;
}

@media (max-width: 1023px) {
    .udk-tablet-sidebar-contents {
        position: fixed;
        display: block !important;
        z-index: 5;
        width: 100%;
        max-width: 300px;
    }

    .show-under-1023 {
        display: block;
    }
}

.udk-scrollable-inner {
    width: 100%;
    overflow-x: hidden;
}

.udk-menublur {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(5px);
}

/* 1720 with sidebar */
@media (max-width: 1720px) {
    body.left-sidebar-open .udk-entitypage-rightsidebar {
        grid-template-columns: 1fr;
        max-width: 450px;
    }
}

/* 1500 without sidebar */
@media (max-width: 1500px) {
    body:not(.left-sidebar-open) .udk-entitypage-rightsidebar {
        grid-template-columns: 1fr;
        max-width: 450px;
    }
}

/* 1720 with sidebar */
@media (max-width: 1300px) {
    body.left-sidebar-open .udk-entitypage-rightsidebar {
        display: none;
    }
}

/* 1720 without sidebar */
@media (max-width: 1100px) {
    body.left-sidebar-open .udk-entitypage-rightsidebar {
        display: none;
    }
}

/* SMALLER DESKTOP */
@media (max-width: 1160px) {
    .udk-mainmenu {
        grid-template-columns: 1fr 840px 1fr;
    }

    .udk-mainmenu-nav {
        grid-template-columns: 480px minmax(220px, 1fr);
    }

    .udk-mm-menugrid {
        grid-template-columns: repeat(auto-fill, 80px);
    }
}

/* TABLET */
@media (max-width: 1023px) {
    .udk-mainmenu-nav {
        grid-template-columns: 300px minmax(220px, 1fr);
    }

    .udk-mainmenu {
        grid-template-columns: 60px 1fr auto;
        grid-gap: 20px;
    }

    .udk-mm-menugrid {
        grid-template-columns: repeat(auto-fill, 100px);
    }

    .udk-layoutcomplex {
        grid-template-columns: 1fr;
    }

    .udk-layoutcomplex > div:nth-child(1) {
        display: none;
    }
}

/* TABLET */
@media (max-width: 768px) {
    .udk-mainmenu-nav {
        grid-template-columns: 240px minmax(220px, 1fr);
    }

    .udk-mm-menugrid {
        grid-template-columns: repeat(auto-fill, 80px);
    }

    .udk-mainmenu-nav {
        grid-gap: 20px;
    }

    .tablet-hide {
        display: none;
    }
}

/* MOBILE OR SM */
@media (max-width: 768px) {
    .mobile-1-col {
        grid-template-columns: 1fr !important;
    }

    .udk-mainmenu {
        display: none;
    }

    .mobile-hide {
        display: none;
    }

    .udk-mm-menugrid {
        grid-template-columns: none;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
    }
}