.content-popup-shadow {
    box-shadow: rgba(101, 119, 134, 0.1) 0px 0px 15px, rgba(101, 119, 134, 0.075) 0px 0px 3px 1px;
}

@media all and (max-width: 768px) {
    .mobile-drawer {
        width: calc(100vw - 16px) !important;
        padding-right: 4px;
        z-index: 50;
    }
    .mobile-drawer-fn .content-popup-shadow {
        box-shadow: none !important;
        border: none !important;
    }
}