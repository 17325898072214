.pretty-switch {
    position: relative;
    appearance: none; outline: none;
    width: 2.5rem;
    height: 1.6rem;
    background-color: #ffffff; border: 1px solid #D9DADC;
    border-radius: 2rem;
    box-shadow: inset -20px 0 0 0 #ffffff;
    transition-duration: 200ms;
}

input.pretty-switch:after {
    content: "";
    position: absolute;
    top: 0.15rem;
    left: 0.2rem;
    width: 1.25rem;
    height: 1.25rem;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 2px 4px 6px rgba(0,0,0,0.1);
}

input.pretty-switch:checked {
    border-color: #4ED164;
    box-shadow: inset 0.9rem 0 0 0 #4ED164;
}

input.pretty-switch:checked:after {
    left: 0.9rem;
    box-shadow: -2px 4px 3px rgba(0,0,0,0.05);
}

.pretty-radio {
    position: relative;
    appearance: none; outline: none;
    width: 1rem;
    height: 1rem;
    background-color: #ffffff;
    border: 1px solid #cdcdcd;
    border-radius: 100%;
    transition-duration: 100ms;
}

input.pretty-radio:after {
    content: "";
    position: absolute;
    top: 0.15rem;
    left: 0.2rem;
    width: 1.25rem;
    height: 1.25rem;
    background-color: transparent;
    border-radius: 50%;
}

input.pretty-radio:checked {
    border: 5px solid #3871c2;
}

input.pretty-radio:checked:after {
    left: 0.9rem;
}