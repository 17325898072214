:root {
    --focus-inner-color: hsla(210, 52%, 42%, 1.00);
    --focus-outer-color: hsla(200, 72%, 83%, 0.75);
}

.input-focus, .htmlselect {
    outline: none;
    border: 1px solid #d4d4d8;
    -webkit-appearance: none;
}

.input-focus.disabled {
    background-color: #efefef;
    border-color: #d4d4d8;
}

.input-focus:hover, .htmlselect:hover {
    border-color: #b3b3b3;
}

.input-focus:focus, .htmlselect:focus {
    box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(58, 151, 212, 0.36), 0 0 0 0 transparent, 0 0 0 1px rgba(60, 66, 87, 0.16), 0 0 0 0 transparent, 0 0 0 0 transparent, 0 0 0 0 transparent;
    border-color: #659adf;
}

.minimal-input {
    padding: 0.25rem 0.5rem;
    font-size: 16px;
    border-radius: 6px;
    background: #efefef;
    color: #3e3e3e;
    font-weight: 500;
    height: 2.125rem;
}