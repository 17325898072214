.directory-gallery-grid {
width: 100% !important;
}

.directory-gallery-grid > div {
    margin: 0 auto;
    position: relative;
}

.fetching-directory {

}