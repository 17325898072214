.external-auth-page {
    display: grid;
    grid-template-columns: 28rem 1fr;
}

.external-auth-page > div {
    height: 100vh;
    overflow-y: auto;
}


/* SMALLER DESKTOP */
@media (max-width: 1160px) {

}

/* TABLET */
@media (max-width: 1023px) {

}


/* MOBILE OR SM */
@media (max-width: 768px) {
    .external-auth-page {
display: flex;
    }
}