.cards-3-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

@media (max-width: 1000px) {
    .cards-3-grid {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}

@media (max-width: 768px) {
    .cards-3-grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}