.box {
    font-size: 1rem;
}

.box__dragndrop,
.box__uploading,
.box__success,
.box__error {
    display: none;
}

.dropbox {
}

.dropbox .drop-note {
    display: none;
}

.dropbox.is-dragover .drop-note {
    display: flex;
}

.box.is-dragover {
    background-color: #dedede;
}

.box.has-advanced-upload {

}

.box.has-advanced-upload .box__dragndrop {
    display: inline;
}

.canvas-editor canvas {
    border-radius: 0.75rem;
}