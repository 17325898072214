.m3-content-view {
    overflow-y: auto;
    -webkit-mask-image: linear-gradient(to bottom, transparent 0, black var(--top-mask-size, 0), black calc(100% - var(--bottom-mask-size, 0)), transparent 100%);
    mask-image: linear-gradient(to bottom, transparent 0, black var(--top-mask-size, 0), black calc(100% - var(--bottom-mask-size, 0)), transparent 100%);
    --top-mask-size: 0px;
    --bottom-mask-size: 0px;
}

.m3-content-view.is-top-overflowing {
    --top-mask-size: 48px !important;
}

.m3-content-view.is-bottom-overflowing {
    --bottom-mask-size: 48px !important;
}