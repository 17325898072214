.pagination-item {
    padding: 0.2rem 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
}

.search-highlight {
    background-color: #f3e292;
    padding: 0rem 0.1rem;
    margin: 0 -0.1rem;
    border-radius: 0.1rem;
}