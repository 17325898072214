.reaction.active {
    border-color: #1D9BD1;
    color: #1264A3;
    background-color: #E8F5FA;
}
.reaction:not(.active):hover {
    border-color: #1D9BD1;
    background-color: #E8F5FA;
}
.reaction:not(.active) {
    color: inherit;
}