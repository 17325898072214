.toc-label-text {
color: rgb(68, 68, 68);
}

.toc-label-text:hover {
color: inherit;
}

.sidebar-height {
    height: calc(100vh - 5rem);
}